<template>
  <div class="scroll-list">
    <Categories />
  </div>
</template>

<script>
import Categories from '@/components/categories/categories.vue'
import * as queries from '@/graphql/queries'
import { API } from 'aws-amplify'

export default {
  components: {
    Categories
  },
  created () {
    this.list()
  },
  methods: {
    async list () {
      try {
       await API.graphql({
           query: queries.listCategorys
       })
      } catch (e) {
        if(e.errors) {
          console.log('Catgeory listing error:', e.errors[0].message)
        } else {
          console.log('Catgeory listing error', e)
        }

        this.res = e
      }
    }
  }
}
</script>

<style>

</style>
