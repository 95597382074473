<template>
    <div class="list">
      <SlickList :useDragHandle="true" lockAxis="y" v-model="categories" @input="saveSorted">
        <Category v-for="(item, index) in categories" :index="index"
        :key="index" :item="item" />
      </SlickList>
    </div>
</template>

<script>

import { API } from 'aws-amplify'
// import * as subscriptions from '@/graphql/subscriptions'
import * as queries from '@/graphql/queries'
import * as mutations from '@/graphql/mutations'
import { SlickList } from 'vue-slicksort'
import Category from './category'
// import Auth from '@/components/enter/Auth'

// let deleteCategorySubscription, createdCategorySubscription

function compare(a, b) {
  // Use toUpperCase() to ignore character casing
  const catA = a.rank
  const catB = b.rank
  let comparison = 0;
  if (catA > catB) {
    comparison = 1;
  } else if (catA < catB) {
    comparison = -1;
  }
  return comparison;
}

export default {
  data () {
    return {
      categories: [],
      loading: true
    }
  },
  components: {
    SlickList,
    // SlickItem,
    Category
  },
  methods: {
    async list () {
      let result

      try {
        result = await API.graphql({
          query: queries.listCategorys,
          limit: 2000,
        })

        this.loading = result.loading
        this.categories = result.data.listCategorys.items.sort(compare)
      } catch (e) {
        console.log(e)
        this.res = e
      }
    },
    saveSorted () {
      this.categories.forEach( newcat => {
        const checkCategory = cat => { return newcat.id === cat.id }
        const index = this.categories.findIndex(checkCategory)
        console.log(newcat.name,' rank', newcat.rank, 'index', index)
      })
      this.categories.forEach( (cat, index) => {
        this.categories[index].rank = index
      })

      this.categories.forEach( (cat) => {
        this.updateCategory (cat)
      })
    },
    async updateCategory (category) {
      // let result
      try {
        delete category.createdAt
        delete category.updatedAt
        delete category.videos
        console.log('savin cat', category)
        await API.graphql({ // result
          query: mutations.updateCategory,
          variables: { input: category }
        })
      } catch (err) {
        this.loading = false
        console.log('Video for chapters :', err)
      }
    },
    deleteCategory (category) {
      console.log('delete category', category.id)
    }
  },

  created () {
    this.list()
  }
}
</script>

<style lang="scss" scoped>
  .list {
      width: 80%;
      max-height: 80vh;
      max-width: 600px;
      margin: 10px;
      overflow: auto;
      // background-color: #f3f3f3;
      // background-color: gray;
      // border: 1px solid #EFEFEF;
      // border-radius: 3;
  }
</style>
