<template>
  <div class="list-item">
    <span class="rank"> {{item.rank}} </span> 
    <v-icon v-handle class="handle">mdi-drag</v-icon>
    
    <v-text-field dense autofocus solo flat hide-details :loading="saving"
      v-model="item.name" 
      placeholder="tittel"
      @keyup.enter="updateCategory"
      outlined
    >
    </v-text-field>
    
  </div>
</template>

<script>
/*
      @focus="disableKeys"
      @blur="enableKeys"
      @keyup.enter="createChapter"
*/
import { ElementMixin, HandleDirective } from 'vue-slicksort';
import { API, graphqlOperation } from 'aws-amplify'
// import * as subscriptions from '@/graphql/subscriptions'
// import * as queries from '@/graphql/queries'
import * as mutations from '@/graphql/mutations'

  export default {
    data () {
      return {
        saving: 'false',
      }
    },
    props: ["item"],
    mixins: [ElementMixin],
    directives: { handle: HandleDirective },
    methods: {
      updateCategory (category) {
        category
        this.saving = 'success'
        console.log(JSON.stringify(this.item))
        API.graphql(
          graphqlOperation(mutations.updateCategory, {
            input: {
              id: this.item.id,
              name: this.item.name
            }
          })
        ).then ( (res) => {
          this.saving = false
          console.log('cat res', res)
        }).catch( e => {
          console.log('feil', e)
          this.saving = false
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .list-item {
    display: grid;
    grid-template-columns: 20px 40px 260px ;
    padding: 6px;
  }
  .rank {
    padding: 8px;
  }
</style>